import React, { useEffect, useRef } from 'react';
import {
	useRecoilCallback, useRecoilState, useRecoilValue,
} from 'recoil';
import { FormApplicationResultSuccess, FormApplicationResultSuccessParsed, submitFormApplication } from 'apiClient/serviceLayer';
import Button from '../Button';
import FormAtomData, { FormData, FormDataValue } from '../../stores/atom/FormData';
import FormConfig from '../../stores/atom/FormConfig';
import FormMandant from '../../stores/atom/FormMandant';
import FormConfigMandant from '../../stores/atom/FormConfigMandant';
import FormProductOwner from '../../stores/atom/FormProductOwner';
import SubmitData from '../../stores/selector/SubmitDataSelector';
import ScreenProgress from '../../stores/atom/ScreenProgress';
import EngagementListAtom from '../../stores/atom/EngagementList';
import wasFormSubmitted from '../../stores/atom/wasFormSubmitted';
import isFormSubmitting from '../../stores/atom/isFormSubmitting';
import TrackingSelector from '../../stores/selector/TrackingSelector';
import ActiveScreenSelector from '../../stores/selector/ActiveScreenSelector';
import SelectedTariff from '../../features/TariffComparison/stores/atom/SelectedTariff';
import { TrackingEvent, Alignment } from '../constants';
import { getAlignment } from '../../utils/getAlignment';
import { scrollToElementWithOffset, validateForms } from '../../utils';
import dataLayerHandler from '../../utils/tracking/dataLayerHandler';
import getInvalidFieldsWithError from '../../utils/tracking/getInvalidFieldsWithError';
import { ButtonProps } from '../Button/Interfaces';

function SubmitButton({ alignment = Alignment.right, ...props }: Readonly<ButtonProps>) {
	const submitData: FormDataValue = useRecoilValue(SubmitData);
	const [currentScreen, setCurrentScreen] = useRecoilState(ScreenProgress);
	const [engagementList, setEngagementList] = useRecoilState(EngagementListAtom);
	const trackingData = useRecoilValue(TrackingSelector);
	const activeScreen = useRecoilValue(ActiveScreenSelector);
	const selectedTariff = useRecoilValue(SelectedTariff);
	const [, setWasSubmitted] = useRecoilState(wasFormSubmitted);
	const [isSubmitting, setIsSubmitting] = useRecoilState(isFormSubmitting);
	const isSubmittingRef = useRef(isSubmitting);

	useEffect(() => {
		isSubmittingRef.current = isSubmitting;
	}, [isSubmitting]);

	const navigateStep = () => {
		const nextScreen = currentScreen + 1;
		if (nextScreen < 0) return;
		setCurrentScreen(nextScreen);
		scrollToElementWithOffset('top-scroll-point', 20);
	};

	const submit = useRecoilCallback(({ snapshot }) => async () => {
		if (isSubmittingRef.current) return;
		setIsSubmitting(true); // Ensure isSubmitting is set to true
		try {
			const { id } = await snapshot.getPromise(FormConfig);
			const configMandant = await snapshot.getPromise(FormConfigMandant);
			const formData = await snapshot.getPromise(FormAtomData);
			const mandant = await snapshot.getPromise(FormMandant);
			const productOwner = await snapshot.getPromise(FormProductOwner);
			const screen = await snapshot.getPromise(ScreenProgress);

			const validForms = validateForms(formData[screen]);

			if (!validForms) {
				setIsSubmitting(false);
				return;
			}
			if (validForms.length) {
				const invalidFields = getInvalidFieldsWithError(formData[currentScreen as keyof FormData]);
				dataLayerHandler({
					event: TrackingEvent.formularError,
					invalidFields,
					trackingData,
					...(selectedTariff?.tariffName && { tariffName: selectedTariff?.tariffName }),
				});
				const validFormElement: string = validForms?.[0];
				if (document.getElementById(validFormElement)) {
					scrollToElementWithOffset(validFormElement);
				}
				setIsSubmitting(false);
				return;
			}
			const checkSuccess = (res: FormApplicationResultSuccess) => {
				const { processSteps } = JSON.parse(res.result) as FormApplicationResultSuccessParsed;
				const unsuccessfulStep = processSteps?.find((step) => !step.successful);
				const isSuccessful = !unsuccessfulStep;
				if (!isSuccessful) throw new Error('Error - Form not successful');
				return isSuccessful;
			};
			await submitFormApplication({
				'config-mandant': configMandant,
				formID: id,
				mandant,
				produktnehmer: productOwner,
				values: submitData,
			}).then(checkSuccess)
				.then(() => {
					setWasSubmitted(true);
					dataLayerHandler({
						event: TrackingEvent.formularSubmit,
						trackingData,
						...(selectedTariff?.tariffName && { tariffName: selectedTariff?.tariffName }),
					});
					setEngagementList({ ...engagementList, previousStep: { id: activeScreen?.id } });
					navigateStep();
				})
				// eslint-disable-next-line no-alert
				.catch(() => alert('Übertragungsfehler: Das Formular kann zurzeit nicht versendet werden'));
		} finally {
			setIsSubmitting(false); // Ensure isSubmitting is set to false
		}
	}, [submitData]);
	return (
		<div style={{ display: 'flex', justifyContent: getAlignment(alignment) }}>
			<Button {...props} onClick={submit} />
		</div>
	);
}

export default SubmitButton;
