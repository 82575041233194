export enum Service {
	formApplication = 'submit',
	formConfig = 'config',
	formMandant = 'mandant',
	formPath = 'formular',
	formUpload = 'upload',
	agencyByNumber = 'agn',
	agencyLocation = 'location',
	tariffCalculation = 'tariffing',
	tariffIds = 'tariffIds',
}
