import React, { useRef, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import '@oev-dxp/oev-components/dist/tariff-comparison';
import '@oev-dxp/oev-components/dist/tariff-card-body';
import '@oev-dxp/oev-components/dist/tariff-header';
import '@oev-dxp/oev-components/dist/tariff-footer';
import '@oev-dxp/oev-components/dist/tariff-table';
import '@oev-dxp/oev-components/dist/tariff-price';
import '@oev-dxp/oev-components/dist/button';
import '@oev-dxp/oev-components/dist/link';
import {
	TariffComparisonMapped,
	TariffComparisonProps,
	TariffComparisonWebComponent,
} from '../interfaces';
import SelectedTariff from '../stores/atom/SelectedTariff';
import HasPricePlaceholder from '../stores/atom/HasPricePlaceholder';
import FormDataMetaDataAtom from '../../../stores/atom/FormDataMetaDataAtom';
import FormAtomData from '../../../stores/atom/FormData';
import ScreenProgress from '../../../stores/atom/ScreenProgress';
import PageConfigAtom from '../../../stores/atom/PageConfig';
import SignedTariffList from '../stores/atom/SignedTariffList';
import { useSelectTariff } from '../hooks/useSelectTariff';
import { useCalculateTariffPrices } from '../hooks/useCalculateTariffPrices';
import { mapTariffDataToComponent } from '../utils';
import { filterTariffRelevantFormData } from '../utils/filterTariffRelevantFormData';

function TariffComparison({ tariffComparison, sectionId }: Readonly<TariffComparisonProps>) {
	// const isMandatory = tariffConfig?.validation?.mandatory?.choice === 'mandatory';
	const [selectedTariff, setSelectedTariff] = useRecoilState(SelectedTariff);
	const currentScreen = useRecoilValue(ScreenProgress);
	const [hasPricePlaceholder, setHasPricePlaceholder] = useRecoilState(HasPricePlaceholder);
	const setSignedTariffList = useSetRecoilState(SignedTariffList);
	const tariffRef = useRef<TariffComparisonWebComponent | null>(null);
	const formData = getRecoil(FormAtomData);
	const formMetaData = getRecoil(FormDataMetaDataAtom);
	const pageConfig = getRecoil(PageConfigAtom);
	const [mappedTariffData, setMappedTariffData] = useState<TariffComparisonMapped>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const isButtonFullWidth = tariffComparison.buttonFullWidth ?? true; // LS-1676 default true

	useEffect(() => {
		setMappedTariffData(mapTariffDataToComponent(tariffComparison, pageConfig));
		if (Object.keys(selectedTariff).length) return;
		setSelectedTariff({ productName: tariffComparison?.productName ?? '' });
	}, []);

	const setTariffRef = (mappedData: TariffComparisonMapped | undefined) => {
		if (!tariffRef.current) return;
		tariffRef.current.tariffs = mappedData;
	};

	const prevFilteredValues = useRef({});
	const filteredValues = filterTariffRelevantFormData(currentScreen, formData, formMetaData);

	useSelectTariff(mappedTariffData, tariffComparison);
	useCalculateTariffPrices({
		filteredValues,
		mappedTariffData,
		sectionId,
		setIsLoading,
		setMappedTariffData,
		setTariffRef,
		tariffComparison,
		tariffRef,
	});

	useEffect(() => {
		if (JSON.stringify(filteredValues) !== JSON.stringify(prevFilteredValues.current)) {
			setSelectedTariff({});
			setSignedTariffList({});
			setHasPricePlaceholder(true);
		}
		prevFilteredValues.current = filteredValues;
	}, [filteredValues]);

	return (
		<oev-row-v1 key={sectionId}>
			<oev-column-v1>
				<oev-tariff-comparison
					{...(hasPricePlaceholder ? { 'price-placeholder': '' } : null)}
					{...(isLoading ? { 'is-loading': '' } : null)}
					{...(isButtonFullWidth ? { 'button-full-width': '' } : null)}
					// {...(isMandatory ? { mandatory: '' } : null)}
					headline={tariffComparison?.headline}
					id="TariffComparison"
					ref={tariffRef}
					sub-headline={tariffComparison?.subHeadline}
				/>
				<small>
					<h6>Tariff state:</h6>
					<div>
						Product name:
						{selectedTariff?.productName}
					</div>
					<div>
						Tariff name:
						{selectedTariff?.tariffName}
					</div>
					<div>
						Tariff ID:
						{selectedTariff?.tariffId}
					</div>
					<div>
						Tariff Signature (last 30 characters):
						{selectedTariff?.signedTariffOffer?.slice(-30)}
					</div>
				</small>
			</oev-column-v1>
		</oev-row-v1>
	);
}

export default TariffComparison;
