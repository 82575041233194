import AgencyLocationSearchData from '../../features/Agency/interfaces/AgencyLocationSearchData';
import { fetchGetRequest } from '../restLayer';
import { Service } from './Constants';
import getFormGatewayUrl from './formGatewayUrl';

export default async function agencyLocationSearch(mandant: string, location: string) {
	const formGatewayUrl = getFormGatewayUrl();
	const locationSearchUrl = `${formGatewayUrl}/${Service.formMandant}/${mandant}/${Service.agencyLocation}/${location}`;
	return fetchGetRequest(locationSearchUrl).then((payload) => payload as AgencyLocationSearchData);
}
