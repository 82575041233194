import React from 'react';
import Button from '../Button';
import { ButtonAction, Alignment } from '../constants';
import { getAlignment } from '../../utils/getAlignment';
import { ButtonProps } from '../Button/Interfaces';

function CalculationButton({ alignment = Alignment.right, ...rest }: Readonly<ButtonProps>) {
	return (
		<div style={{ display: 'flex', justifyContent: getAlignment(alignment) }}>
			<Button {...rest} action={ButtonAction.calculation} eventLabel="@TariffComparison/calculate" />
		</div>
	);
}

export default CalculationButton;
