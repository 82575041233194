import { getRecoil } from 'recoil-nexus';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { shouldCalculate } from '../utils/shouldCalculate';
import dataLayerHandler from '../../../utils/tracking/dataLayerHandler';
import getInvalidFieldsWithError from '../../../utils/tracking/getInvalidFieldsWithError';
import { getTariffCalculation } from '../api/getTariffCalculation';
import {
	SignedTariffListAtom,
	TariffCalculationRequestData,
	TariffCalculationResponseData,
	TariffComparisonMapped,
	TariffDataDecoded,
	TariffDataDetails,
} from '../interfaces';
import { UseCalculateTariffPricesProps } from '../interfaces/UseCalculateTariffPricesProps';
import { TariffEvent } from '../components/Constants';
import { TrackingEvent } from '../../../components/constants';
import FormAtomData from '../../../stores/atom/FormData';
import FormDataMetaDataAtom from '../../../stores/atom/FormDataMetaDataAtom';
import HasPricePlaceholder from '../stores/atom/HasPricePlaceholder';
import SignedTariffList from '../stores/atom/SignedTariffList';
import EngagementListAtom from '../../../stores/atom/EngagementList';
import TrackingSelector from '../../../stores/selector/TrackingSelector';

export const useCalculateTariffPrices = ({
	filteredValues,
	mappedTariffData,
	setIsLoading,
	setMappedTariffData,
	setTariffRef,
	tariffComparison,
	tariffRef,
}: UseCalculateTariffPricesProps) => {
	const formData = getRecoil(FormAtomData);
	const formMetaData = getRecoil(FormDataMetaDataAtom);
	const engagementList = useRecoilValue(EngagementListAtom);
	const trackingData = useRecoilValue(TrackingSelector);
	const setSignedTariffList = useSetRecoilState(SignedTariffList);
	const setHasPricePlaceholder = useSetRecoilState(HasPricePlaceholder);
	const tariffIds: string[] = tariffComparison?.tariffDataDetails?.map((tariff) => tariff?.id ?? '');
	const [isTariffRecalculated, setIsTariffRecalculated] = useState<boolean>(false);

	const calculateTariffPrices = async (isTariffComparisonManuallyCalculated: boolean = true) => {
		if (!shouldCalculate(
			isTariffComparisonManuallyCalculated,
			filteredValues,
			trackingData,
		)) return;

		const invalidFormFields = getInvalidFieldsWithError(
			filteredValues,
			true,
			isTariffComparisonManuallyCalculated,
		);

		if (!invalidFormFields?.length) {
			const fieldsForCalculation: TariffCalculationRequestData = { profile: { ...filteredValues } };
			setIsLoading(true);
			const tariffCalculationResult = await getTariffCalculation(
				tariffIds,
				fieldsForCalculation,
			);
			setIsLoading(false);

			if (engagementList.engagedItems.length > 0) {
				dataLayerHandler({ event: TrackingEvent.profilerCalculate, trackingData });
			}

			if (tariffCalculationResult && 'errordetail' in tariffCalculationResult) {
				throw new Error('Invalid tariff calculation response');
			}

			const tariffCalculationResultDecoded: Array<TariffDataDecoded> = (
				tariffCalculationResult as TariffCalculationResponseData
			)?.map((token) => jwtDecode(token));

			const signedTariffList: SignedTariffListAtom = tariffCalculationResultDecoded
				?.reduce((accumulator: SignedTariffListAtom, result: TariffDataDecoded, index: number) => {
					const signedTariff = { ...accumulator };
					signedTariff[result?.offer?.id] = (
						tariffCalculationResult as TariffCalculationResponseData
					)[index];
					return signedTariff;
				}, {});
			setSignedTariffList(signedTariffList);

			const updatedData: TariffDataDetails = mappedTariffData?.tariffData?.map((tariff) => {
				const matchingTariff = tariffCalculationResultDecoded?.find(
					(result) => result?.offer?.id === tariff?.id,
				);
				if (matchingTariff) {
					/* eslint-disable no-param-reassign */
					tariff.premium = matchingTariff?.offer?.premium?.displayedValue;
					tariff.visible = matchingTariff?.offer?.visible;
				}
				return tariff;
			}) ?? [];

			setMappedTariffData({
				...mappedTariffData,
				tariffData: updatedData,
			} as TariffComparisonMapped);
			setHasPricePlaceholder(false);
		}
	};

	const calculateTariffPricesHandler = () => calculateTariffPrices(true);

	useEffect(() => {
		// document DOM access necessary, since it is not clear where the event is triggered from
		document.addEventListener(TariffEvent.calculate, calculateTariffPricesHandler);

		return () => {
			document.removeEventListener(TariffEvent.calculate, calculateTariffPricesHandler);
		};
	}, [tariffComparison, mappedTariffData, formData, formMetaData]);

	useEffect(() => {
		if (tariffRef?.current) setTariffRef(mappedTariffData);
		if (!mappedTariffData || isTariffRecalculated) return;

		calculateTariffPrices(false);
		setIsTariffRecalculated(true);
	}, [tariffComparison, mappedTariffData]);
};
